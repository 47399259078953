const contactURL = process.env.GATSBY_REACT_APP_CONTACT_URL;
const contactHeader = process.env.GATSBY_REACT_APP_AZF;

// Contact form submission
export function contactSubmission (postBody) {
    console.log(contactURL)
    return fetch(contactURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-functions-key': contactHeader,
        },
        body: postBody,
    })
        .then(async response => {
            const data = await response.json();
            console.log(data);
            return data;
        })

}