import * as React from "react";

// react
import { useEffect, useState } from "react";
//import { useSelector, useDispatch } from 'react-redux'
import toast from "react-hot-toast";
import { Link } from "gatsby";

// Services
import { contactSubmission } from "../../../services/contactAPI";

// Hooks
//import useAuth from "../../../hooks/auth";
//import useConfirm from "../../../hooks/confirm";

// SVGs
import LogoSvg from "../../../images/logo/logo.inline.svg";
import TextLogoSvg from "../../../images/logo/textLogo.inline.svg";
import ButtonPressingHand from "../../../images/footer/button-pressing-hand.inline.svg";

// Shared Components
import LoadingButton from "../../../components/shared/loading-button/LoadingButton";
import CustomToast from "../../../components/shared/toast/CustomToast";

export default function Footer({ location, data }) {
  //const { authed } = useAuth();
  //const { confirm } = useConfirm();

  const change_footer_color_routes = [
    "/mobile-app",
    "/granular-permissions",
    "/testing-utility",
    "/work-queue",
    "/how-it-works",
    "/compatible-central-stations",
  ];

  const authed_footer_color_routes = ["/dashboard"];

  const [textBoxColor, setTextBoxColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [interestFormSent, setinterestFormSent] = useState(false)


  //For redux when dispactching reducers
  //const dispatch = useDispatch()

  //Getting current location
  // const location = useLocation();

  //For navigation and not clearing redux store
  // let navigate = useNavigate();

  useEffect(() => {
    function getLocation() {
      if (change_footer_color_routes.includes(location)) {
        setBgColor("bg-navy");
        setTextBoxColor("bg-navy-light");
        setTextColor("text-white placeholder-white");
      } else if (authed_footer_color_routes.includes(location)) {
        setBgColor("bg-myGray-300");
        setTextBoxColor("bg-white");
        setTextColor("text-black placeholder-black");
      } else {
        setBgColor("bg-navy");
        setTextBoxColor("bg-navy-light");
        setTextColor("text-white placeholder-white");
      }
    }

    getLocation();
  }, [location]);

  const handleSubmit = async (event) => {
    //Preventing page from reloading
    event.preventDefault();
    setLoading(true);

    let postBody = JSON.stringify({
      email: email,
      PageName: "Footer",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        setinterestFormSent(true)
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"success"}
              title={"Success!"}
              message={
                "Thank you for your interest in Tech Tester! Please check your inbox for updates."
              }
            />
          ),
          { duration: 3000 }
        );
        setLoading(false);
        event.target.reset();
      } else {
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"failure"}
              title={"Error"}
              message={"Something went wrong. Please try again."}
            />
          ),
          { duration: 3000 }
        );
        setLoading(false);
        event.target.reset();
      }
    });

    //temporarily commented out code is API call to signup

    // await post('signup', postBody).then(async response => {
    //     const data = await response;
    //     console.log('data returned to request: ' + JSON.stringify(data));
    //     if (data){
    //         if (data.message === "Please check your email to complete account registration.") {
    //             navigate('/email-sent-confirmation', { state: {email:email }});
    //         } else {
    //             toast.custom((t) =>
    //                 (<CustomToast toast={t} toastType={'failure'} title={'Error'} message={"Account could not be created. Please try again."}/>), {duration: 3000}
    //             )
    //         }
    //     }
    //
    // }).catch(async error => {
    //     const isConfirmed = await confirm("This email address is already in use. Would you like to resend the confirmation email?");
    //     if (isConfirmed) {
    //         // RESEND EMAIL
    //         await post(`signup/resend`, JSON.stringify({'email': email})).then((data) => {
    //             console.log('data returned to request: ' + JSON.stringify(data));
    //             if (data.message === "Please check your email to complete account registration.") {
    //                 navigate('/email-sent-confirmation', { state: {email:email }});
    //             } else {
    //                 toast.custom((t) =>
    //                     (<CustomToast toast={t} toastType={'failure'} title={'Error'} message={data.message}/>), {duration: 3000}
    //                 )
    //                 navigate('/signup');
    //             }
    //         })
    //         console.error('Error returned from API!', error);
    //     }
    // });
  };

  return (
    <div className={`relative flex justify-evenly ${bgColor}`}>
      {/*{ (authed && authed_footer_color_routes.includes(location.pathname)) ? (<div className="px-10">*/}
      {/*        <div*/}
      {/*            className="grid grid-cols-1 md:grid-cols-4 gap-10 mx-auto justify-between place-items-center pt-6 pb-0">*/}
      {/*            <div className={'grid grid-cols-1 justify-items-center'}>*/}
      {/*                <LogoSvg className="h-20 w-20 text-center fill-gray-500"/>*/}
      {/*                <TextLogoSvg className={'h-4 mt-4 fill-gray-500'}/>*/}
      {/*            </div>*/}
      {/*            <div className="text-center md:text-left">*/}
      {/*                <h3 className="text-md font-semibold text-gray-500 tracking-wider uppercase">*/}
      {/*                    Quick Links*/}
      {/*                </h3>*/}
      {/*                <ul role="list" className="">*/}
      {/*                    <li>*/}
      {/*                        <a href="#" className="text-sm text-gray-400 hover:text-white">*/}
      {/*                            Dashboard*/}
      {/*                        </a>*/}
      {/*                    </li>*/}

      {/*                    <li>*/}
      {/*                        <a href="#" className="text-sm text-gray-400 hover:text-white">*/}
      {/*                            FAQ*/}
      {/*                        </a>*/}
      {/*                    </li>*/}

      {/*                    <li>*/}
      {/*                        <a href="#" className="text-sm text-gray-400 hover:text-white">*/}
      {/*                            Terms and Privacy*/}
      {/*                        </a>*/}
      {/*                    </li>*/}
      {/*                </ul>*/}
      {/*            </div>*/}

      {/*            <div className="text-center md:text-left">*/}
      {/*                <h3 className="text-md font-semibold text-myGray-500 tracking-wider uppercase">*/}
      {/*                    Resources*/}
      {/*                </h3>*/}
      {/*                <ul role="list" className="">*/}
      {/*                    <li>*/}
      {/*                        <a href="#" className="text-sm text-gray-400 hover:text-white">*/}
      {/*                            Help Center*/}
      {/*                        </a>*/}
      {/*                    </li>*/}

      {/*                    <li>*/}
      {/*                        <a href="#" className="text-sm text-gray-400 hover:text-white">*/}
      {/*                            Online Payment Help*/}
      {/*                        </a>*/}
      {/*                    </li>*/}

      {/*                    <li>*/}
      {/*                        <a href="#" className="text-sm text-gray-400 hover:text-white">*/}
      {/*                            Referral Program*/}
      {/*                        </a>*/}
      {/*                    </li>*/}
      {/*                </ul>*/}
      {/*            </div>*/}
      {/*            <div className="text-center md:text-left">*/}

      {/*                <h3 className="text-md font-semibold text-myGray-500 tracking-wider uppercase">*/}
      {/*                    Get in Touch*/}
      {/*                </h3>*/}
      {/*                <ul role="list" className="">*/}
      {/*                    <li>*/}
      {/*                        <a href="#" className="text-sm text-gray-400 hover:text-white">*/}
      {/*                            Contact Us*/}
      {/*                        </a>*/}
      {/*                    </li>*/}

      {/*                    <li>*/}
      {/*                        <a href="#" className="text-sm text-gray-400 hover:text-white">*/}
      {/*                            Live Chat*/}
      {/*                        </a>*/}
      {/*                    </li>*/}

      {/*                    <li>*/}
      {/*                        <a href="#" className="text-sm text-gray-400 hover:text-white">*/}
      {/*                            Something Else*/}
      {/*                        </a>*/}
      {/*                    </li>*/}
      {/*                </ul>*/}
      {/*            </div>*/}
      {/*        </div>*/}

      {/*        <div className="mt-8 border-t flex border-myGray-400 pt-4 justify-between">*/}
      {/*            <div className="grid grid-cols-1 mb-8">*/}
      {/*                <p className="mt-8 text-sm text-myGray-500 md:mt-0 md:order-1">*/}
      {/*                    &copy; Tech Tester Inc. 2021*/}
      {/*                </p>*/}
      {/*            </div>*/}
      {/*            <div className="grid grid-cols-3 mb-9 place-items-end">*/}
      {/*                <a href="#" className="text-myGray-500 hover:text-white  px-2">*/}
      {/*                    <span className="sr-only">Facebook</span>*/}
      {/*                    <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">*/}
      {/*                        <path fillRule="evenodd"*/}
      {/*                              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"*/}
      {/*                              clipRule="evenodd"/>*/}
      {/*                    </svg>*/}
      {/*                </a>*/}

      {/*                <a href="#" className="text-myGray-500 hover:text-white  px-2">*/}
      {/*                    <span className="sr-only">Instagram</span>*/}
      {/*                    <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">*/}
      {/*                        <path fillRule="evenodd"*/}
      {/*                              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"*/}
      {/*                              clipRule="evenodd"/>*/}
      {/*                    </svg>*/}
      {/*                </a>*/}

      {/*                <a href="#" className="text-myGray-500 hover:text-white  px-2">*/}
      {/*                    <span className="sr-only">Twitter</span>*/}
      {/*                    <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">*/}
      {/*                        <path*/}
      {/*                            d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>*/}
      {/*                    </svg>*/}
      {/*                </a>*/}
      {/*            </div>*/}
      {/*        </div>*/}

      {/*    </div>)*/}
      {/*    : (*/}
      <div className="px-10">
        <div className="grid place-items-center mb-6">
          <ButtonPressingHand />
        </div>
        <div className="mb-6">
          <h1 className="text-white text-center font-sans text-3xl justify-self-center">
            It's free to try. We hope you do.
          </h1>
        </div>
        <div className="mx-auto">
          <form className="row-auto py-5" onSubmit={handleSubmit}>
            <div className="mx-auto max-w-2xl">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-between">
                <div className="mt-1 md:col-span-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="Email"
                    required
                    className={`appearance-none block px-5 py-3 rounded-md shadow-lg focus:outline-none ${textColor} ${textBoxColor} w-full text-sm`}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <div className="mt-1">
                  {loading ? (
                    <LoadingButton type={"email"} />
                  ) : (
                      <button className={`w-full flex justify-center px-5 py-3 border border-transparent rounded-md shadow-sm text-sm  font-bold ${interestFormSent ? "bg-greenTT text-white" : "bg-yellowTT hover:bg-yellowTT-alt text-black"}`}>
                        {interestFormSent ? "SENT" : "START FOR FREE"}
                      </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-10 mx-auto justify-between place-items-center md:place-items-start py-10">
          <div className={"grid grid-cols-1 justify-items-center mt-8"}>
            <Link to={'/'} className={'text-center justify-center'}>
            <LogoSvg className="h-20 w-20 text-center fill-white" />
            </Link>
            <Link to={'/'} className={'text-center justify-center'}>
              <TextLogoSvg className={"h-4 mt-4 fill-white"} /></Link>
          </div>

          <div className="text-center md:text-left">
            <h3 className="text-md font-semibold text-white tracking-wider uppercase">
              Site Navigation
            </h3>
            <ul >
              <li>
                <Link
                  to="/features/testing-utility"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Testing Utility
                </Link>
              </li>

              <li>
                <Link
                  to="/features/work-queue"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Work Queue
                </Link>
              </li>

              <li>
                <Link
                  to="/features/granular-permissions"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Granular Permissions
                </Link>
              </li>

              <li>
                <Link
                  to="/features/mobile-app"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Mobile Application
                </Link>
              </li>
              <li>
                <Link
                  to="/resources/how-it-works"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  How It Works
                </Link>
              </li>
              <li>
                <Link
                  to="/resources/compatible-central-stations"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Compatible Central Stations
                </Link>
              </li>
            </ul>
          </div>

          <div className="text-center md:text-left">
            <h3 className="text-md font-semibold text-white tracking-wider uppercase">
              Resources
            </h3>
            <ul >
              <li>
                <Link
                  to="/faq"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  FAQ
                </Link>
              </li>

              <li>
                <Link
                  to="/contact"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Payment Processing
                </Link>
              </li>

              <li>
                <Link
                  to="/pricing"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Pricing
                </Link>
              </li>

              <li>
                <Link
                  to="/contact"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Support
                </Link>
              </li>
            </ul>
          </div>
          <div className="text-center md:text-left">
            <h3 className="text-md font-semibold text-white tracking-wider uppercase">
              Tech Tester
            </h3>
            <ul >
              <li>
                <Link
                  to="/app/login"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Login/Sign Up
                </Link>
              </li>

              <li>
                <Link
                  to="/about"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  About
                </Link>
              </li>

              <li>
                <Link
                  to="/contact"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Contact Us
                </Link>
              </li>

              <li>
                <Link
                  to="/terms-of-service"
                  className="text-sm text-gray-300 hover:text-white"
                >
                  Terms and Privacy
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 border-t grid grid-row-2 md:flex border-white pt-8 justify-center md:justify-between">
          <div className="grid grid-cols-1 mb-8">
            <p className="text-xs md:text-sm text-white md:mt-0 md:order-1">
              &copy; Alarm Tech Tester 2022 - Powered by{" "}
              <span className={"hover:underline"}>
                <a href={"https://www.kirkwork.co"}>kirkwork</a>
              </span>
            </p>
          </div>
          <div className="grid grid-cols-2 mb-9 place-items-center md:place-items-end">
            <a href="https://www.facebook.com/alarmtechtester" className="text-white hover:text-white  px-2">
              <span className="sr-only">Facebook</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>

            <a href="https://www.instagram.com/alarmtechtester/" className="text-white hover:text-white  px-2">
              <span className="sr-only">Instagram</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      {/*)}*/}
    </div>
  );
}
