import * as React from "react";

// React
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "gatsby";

// Heroicons
import {
  AdjustmentsIcon,
  BriefcaseIcon,
  LockClosedIcon,
  DeviceMobileIcon,
  OfficeBuildingIcon,
  MenuIcon,
  XIcon,
  BadgeCheckIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

// SVG
import LogoSvg from "../../../images/logo/logo.inline.svg";
import TextLogoSvg from "../../../images/logo/textLogo.inline.svg";

const features = [
  {
    name: "Testing Utility",
    description:
      "Easily ensure all sensors have communicated. No more scanning through history records.",
    href: "/features/testing-utility",
    icon: AdjustmentsIcon,
  },
  {
    name: "Work Queue",
    description: "Tech management and access that empowers you and your team.",
    href: "/features/work-queue",
    icon: BriefcaseIcon,
  },
  {
    name: "Granular Permissions",
    description:
      "Give your techs as much or as little access to your accounts as you desire. Rest easy knowing access to your account base is secure.",
    href: "/features/granular-permissions",
    icon: LockClosedIcon,
  },
  {
    name: "Mobile Application",
    description:
      "Our native mobile application gives your techs the power they need right from their device.",
    href: "/features/mobile-app",
    icon: DeviceMobileIcon,
  },
];
const resources = [
  {
    name: "How it Works",
    description: "Learn how Tech Tester can improve your business.",
    href: "/resources/how-it-works",
    icon: BadgeCheckIcon,
  },
  {
    name: "Compatible Central Stations",
    description: "See if your central station works with Tech Tester.",
    href: "/resources/compatible-central-stations",
    icon: OfficeBuildingIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({ location, data }) {

  const change_header_color_routes = [
    "/mobile-app",
    "/granular-permissions",
    "/testing-utility",
    "/work-queue",
    "/how-it-works",
    "/compatible-central-stations",
    "/dashboard",
    "/contact",
    "/faq",
    "/terms-of-service",
    "/about",
  ];

  const [logoColor, setLogoColor] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");

  useEffect(() => {
    function getLocation() {
      if (change_header_color_routes.includes(location)) {
        setLogoColor("fill-black");
        setBgColor("bg-myGray-300");
        setTextColor("text-black");
      } else {
        setLogoColor("fill-white");
        setBgColor("bg-navy");
        setTextColor("text-white");
      }
    }
    getLocation();
  }, []);

  return (
    <div className={`${bgColor}`}>
      <Popover className={`relative ${bgColor} z-50`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <span className="sr-only">Workflow</span>
                <LogoSvg className={`h-7 w-auto inline ${logoColor}`} />
                <TextLogoSvg
                  className={`hidden lg:inline h-5 pl-4 w-auto inline ${logoColor}`}
                />
              </Link>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button
                className={`rounded-md p-2 inline-flex items-center justify-center ${textColor} hover:text-darkBrown hover:bg-myGray-200`}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-yellowTT" : textColor,
                        "group inline-flex items-center text-base font-medium hover:text-yellowTT"
                      )}
                    >
                      <span>FEATURES</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-yellowTT" : textColor,
                          "ml-2 h-5 w-5 group-hover:text-yellowTT"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {features.map((item) => (
                              <Link
                                key={item.name}
                                to={item.href}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 hover:text-yellowTT"
                              >
                                <item.icon
                                  className="flex-shrink-0 h-6 w-6 "
                                  aria-hidden="true"
                                />
                                <div className="ml-4">
                                  <p className="text-base font-medium">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-yellowTT" : textColor,
                        "group inline-flex items-center text-base font-medium hover:text-yellowTT"
                      )}
                    >
                      <span>RESOURCES</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-yellowTT" : textColor,
                          "ml-2 h-5 w-5 group-hover:text-yellowTT"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {resources.map((item) => (
                              <Link
                                key={item.name}
                                to={item.href}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 hover:text-yellowTT"
                              >
                                <item.icon
                                  className="flex-shrink-0 h-6 w-6"
                                  aria-hidden="true"
                                />
                                <div className="ml-4">
                                  <p className="text-base font-medium">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Link
                to="/pricing"
                className={`text-base font-medium ${textColor} hover:text-yellowTT`}
              >
                PRICING
              </Link>
            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a
                href="/app"
                className={`whitespace-nowrap text-base font-medium ${textColor} hover:text-yellowTT`}
              >
                LOG IN
              </a>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <LogoSvg className={`h-7 w-auto inline ${logoColor}`} />
                    <TextLogoSvg
                      className={`hidden lg:inline h-5 pl-4 w-auto inline ${logoColor}`}
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {features.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 hover:text-yellowTT"
                      >
                        <item.icon
                          className="flex-shrink-0 h-6 w-6"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base font-medium">
                          {item.name}
                        </span>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  <Link
                    to="/pricing"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Pricing
                  </Link>

                  <Link
                    to="/contact"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Contact
                  </Link>
                  {resources.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div>
                  <Link
                    to="/contact"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-semibold text-black bg-yellowTT hover:bg-yellowTT-alt"
                  >
                    Sign up
                  </Link>
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Existing customer?{" "}
                    <Link
                      to="/contact"
                      className="text-yellowTT hover:text-darkBrown"
                    >
                      Log In
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
